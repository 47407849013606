/* Bliss */
const $ = window.Bliss

export class Accordion {
  constructor(el) {
    this.el = el
    this.headerA = $("[data-accordion-header] > a", this.el)
    this.contentWrapper = $("[data-accordion-contents-wrapper]", this.el)
    this.content = $("[data-accordion-contents]", this.el)
    this.isOpen = false
    this.openClass = "open"
    this.transitionClass = "transitioning"

    this.init()
    this.setListeners()
  }

  init() {
    if (this.headerA) {
      this.headerA._.set({
        role: "button",
        "aria-expanded": "false",
        "aria-controls": this.el.dataset.accordionId,
      })
      this.content._.set({
        id: this.el.dataset.accordionId,
      })
    } else {
      // console.error('accordion header needs <a> tag inside');
    }

    if (this.el.classList.contains(this.openClass)) {
      this.contentWrapper.style.height = "auto"
      this.isOpen = true
      this.headerA._.set({
        "aria-expanded": "true",
      })
    } else {
      this.setContentWrapperHeight(0)
    }

    if (window.location.hash === `#${this.el.id}`) {
      this.toggle()
    }
  }

  setListeners() {
    this.headerA.addEventListener("click", (e) => {
      e.preventDefault()
      this.toggle()
    })
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.el.classList.add(this.openClass)
    this.el.classList.add(this.transitionClass)
    this.setContentWrapperHeight(this.getContentHeight())
    this.isOpen = true
    $.once(this.el, {
      transitionend: () => {
        this.contentWrapper.style.height = "auto"
        this.el.classList.remove(this.transitionClass)
      },
    })
    this.headerA._.set({
      "aria-expanded": "true",
    })
  }

  close() {
    this.el.classList.remove(this.openClass)
    this.el.classList.add(this.transitionClass)
    this.setContentWrapperHeight(this.getContentHeight())
    this.isOpen = false

    // Set the height not before but at the next repaint by calling rAF twice.
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        this.setContentWrapperHeight(0)
      })
    })

    this.headerA._.set({
      "aria-expanded": "false",
    })
    $.once(this.el, {
      transitionend: () => {
        this.el.classList.remove(this.transitionClass)
      },
    })
  }

  getContentHeight() {
    return this.contentWrapper.scrollHeight
  }

  setContentWrapperHeight(height) {
    this.contentWrapper.style.height = `${height}px`
  }
}
