// special Gallery from SRG Insider
import { SwipeDetector } from "swipedetector"
import "blissfuljs/bliss.shy.min.js"
const $ = window.Bliss
const $$ = $.$

export function modulo(p, q) {
  // A modulo function which actually returns a value with the sign of the
  // divisor.
  let remainder = p % q
  if (q > 0) return (remainder + q) % q
  return (remainder - q) % q
}

export function clamp(value, min, max) {
  return Math.max(Math.min(value, max), min)
}

export function applyTransform(element, transform, transition) {
  element.style.webkitTransform =
    element.style.MozTransform =
    element.style.msTransform =
    element.style.OTransform =
    element.style.transform =
      transform
  element.style.WebkitTransition = element.style.MozTransition = transition
}

export class Gallery {
  constructor(element, options) {
    this.element = element
    this.slider = $("[data-slider]", this.element)
    this.slides = $.$("[data-slide]", this.element)
    this.thumbsContainer = $("[data-thumbs]", this.element)
    this.metricContainer = $("[data-metric-container]")
    this.playPause = $("[data-playpause]", this.element)

    // this.metric = this.slides.dataset.fact
    // this.metric = this.slides
    //
    // console.log(this.metric);

    this.options = $.extend(
      {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      },
      options,
    )

    this._current = null
    this._interval = null

    this._setWidthsAndPositions()

    this.options.endless && this._prepareEndless()

    this.element.dataset.hideControls = this.slides.length <= 1

    this.options.createThumbs && this._createThumbs()

    this.thumbs = $.$("[data-thumb]", this.element)

    this._createMetrics()
    this.metrics = $$("[data-metric]")

    this._setEventListeners()

    this.reveal(0)

    this.autoPlay = this.options.autoPlay
  }

  move(direction) {
    this.autoPlay = false
    this.reveal(this._current + direction)
  }

  get autoPlay() {
    return this._interval != null
  }

  set autoPlay(enable) {
    if (enable) {
      this.element.dataset.playing = true
      if (!this._interval) {
        this._interval = setInterval(
          () => this.reveal(this._current + 1),
          this.options.interval,
        )
      }
    } else {
      this.element.dataset.playing = false
      if (this._interval) {
        clearInterval(this._interval)
        this._interval = null
      }
    }
  }

  reveal(index) {
    this.slides.forEach((slide, index) => {
      if (
        index < this._current ||
        index >= this._current + this.options.visibleSlides
      )
        slide.dataset.slidePosition = -1
      else slide.dataset.slidePosition = index - this._current
    })

    let dir = index - this._current / Math.abs(index - this._current)

    this.thumbs[this._current] &&
      this.thumbs[this._current].removeAttribute("data-current")

    this.metrics[this._current] &&
      this.metrics[this._current].removeAttribute("data-current")
    this._current = modulo(
      index,
      this.slides.length - Math.floor(this.options.visibleSlides) + 1,
    )

    let delta = Math.abs(index - this._current)
    if (this.options.endless && delta > 1) {
      applyTransform(
        this.slider,
        `translate3d(-${
          (this.width * this._current) / this.options.visibleSlides
        }px, 0, 0)`,
        `none`,
      )
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          this.reveal(this._current + dir)
        })
      })
    } else {
      applyTransform(
        this.slider,
        `translate3d(-${
          (this.width * this._current) / this.options.visibleSlides
        }px, 0, 0)`,
        `transform 0.5s ease-in-out`,
      )

      this.thumbs[this._current] &&
        this.thumbs[this._current].setAttribute("data-current", "")

      this.metrics[this._current] &&
        this.metrics[this._current].setAttribute("data-current", "")
    }
  }

  _prepareEndless() {
    let firstSlideClone = this.slides[0].cloneNode(true)
    this.slider.appendChild(firstSlideClone)
    this.slides.push(firstSlideClone)
  }

  _setWidthsAndPositions() {
    this.width = parseFloat(
      getComputedStyle(this.element).getPropertyValue("width"),
    )
    this.slider.style.width = `${this.width * this.slides.length}px`
    this.slides.forEach((slide) => {
      slide.style.width = `${this.width / this.options.visibleSlides}px`
    })
  }
  _setEventListeners() {
    this.thumbs.forEach((thumb, index) => {
      thumb.addEventListener("click", (e) => {
        e.preventDefault()
        this.autoPlay = false
        this.reveal(index)
      })
    })

    // add 'click' to left and right arrow
    $.$("[data-go]", this.element).forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault()
        this.autoPlay = false
        this.reveal(this._current + parseInt(e.currentTarget.dataset.go, 10))
      })
    })

    this.playPause &&
      this.playPause.addEventListener("click", (e) => {
        e.preventDefault()
        this.autoPlay = !this.autoPlay
      })

    let events = new SwipeDetector(this.element).emitter

    events.on("left", () => {
      this.autoPlay = false
      this.reveal(this._current + 1)
    })
    events.on("right", () => {
      this.autoPlay = false
      this.reveal(this._current - 1)
    })

    window.addEventListener("resize", () => {
      this._setWidthsAndPositions()
      this.reveal(this._current)
    })
  }

  _createThumbs() {
    if (this.thumbsContainer) {
      if (this.slides.length > 1) {
        this.slides.forEach(() => {
          this.thumbsContainer.appendChild(
            $.create({
              tag: "a",
              href: "",
              "data-thumb": "",
            }),
          )
        })
      }
    } else {
      //console.warn('bliss-gallery: no element with [data-thumbs] found.')
    }
  }

  _createMetrics() {
    if (this.metricContainer) {
      if (this.slides.length > 1) {
        this.slides.forEach((e) => {
          this.metricContainer.appendChild(
            $.create({
              tag: "span",
              class: "text--white text--bold text--condensed h1",
              "data-metric": e.dataset.fact,
              textContent: e.dataset.fact,
            }),
          )
        })
      }
    } else {
      //console.warn('bliss-gallery: no element with [data-thumbs] found.')
    }
  }
}
