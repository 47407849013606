import { getCookie } from "./utils"
import { refreshCaptchaToken } from "./captcha"

function showErrors(form, errors) {
  for (let k in errors) {
    let ul = document.createElement("ul")
    ul.classList.add("errorlist")

    errors[k].forEach((e) => {
      let li = document.createElement("li")
      li.appendChild(document.createTextNode(e))
      ul.appendChild(li)
    })

    if (k == "__all__") {
      let firstChild = form.children[0]

      if (firstChild.classList.contains("errorlist")) {
        form.replaceChild(ul, firstChild)
      } else {
        form.insertBefore(ul, firstChild)
      }
    } else {
      let input = form.querySelector(`[name=${k}]`)
      input.after(ul)
    }
  }
}

function sendForm(form) {
  refreshCaptchaToken(form).then((form) => {
    let data = {
      csrfmiddlewaretoken: form.csrfmiddlewaretoken,
      petition: form.petition.value,
      email: form.email.value,
      given_name: form.given_name.value,
      family_name: form.family_name.value,
      place: form.place.value,
      wants_newsletter: form.wants_newsletter.value,
      captcha: form.captcha.value,
    }

    fetch(form.action, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status == 400) {
          response.json().then((data) => {
            showErrors(form, data)
          })
        } else if (response.redirected) {
          window.location.href = response.url
        } else {
          return Promise.reject(response)
        }
      })
      .catch((response) => {
        showErrors(form, {
          __all__: ["Sad :("],
        })
      })
  })
}

function initSignatureForms() {
  Array.prototype.forEach.call(
    document.querySelectorAll("form.signature-form"),
    (form) => {
      form.addEventListener("submit", (e) => {
        e.preventDefault()
        sendForm(form)
      })
    },
  )
}

export default initSignatureForms
